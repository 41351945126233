.Backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: red;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
}
.Open {
    display: block !important;
}