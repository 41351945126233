.Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 70%;
    min-width: 65%;
    background-color: #ff7d9d;
    /* padding: 1rem; */
    border-radius: 15px;
    box-shadow: 0 0 8px rgb(20, 20, 20);
    z-index: 9999;
}
.Open {
    display: block;
}