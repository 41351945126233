.ArtistSkeleton {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d3d3d3;
    padding: 1rem;
    overflow: hidden;
}
.ArtistSkeleton::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100px;
    width: 20px;
    height: 100%;
    background-color: #d3d3d3;
    animation: loading 1000ms forwards infinite ease-in-out;
    box-shadow: 0 0 20px 30px #d3d3d3;
}
.Avatar {
    width: 120px;
    height: 120px;
    background-color: white;
    margin-bottom: 0.5rem;
    border-radius: 50%;
}
.Name {
    width: 100px;
    height: 20px;
    background-color: white;
}
@keyframes loading{
    to{
        left: 400px;
    }
}