.Category {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #05243a;
    text-decoration: none;
    margin: 0.5rem 0;
    background-color: #00000023;
    text-align: center;
    font-size: 1.5rem;
}
.Category:active {
    background-color: #ff7d9d;
}

@media (min-width: 700px) and (min-height: 500px) {
    .Category {
        position: relative;
        background-color: #ff7d9d;
        justify-content: start;
        padding: 0 1rem;
        font-size: 1.2rem;
    }
    .Category:hover {
        background-color: #be5b74;
    }
    .Category.Active::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 5px;
        height: 100%;
        background-color: #05243a;
    }
}