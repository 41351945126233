.AlbumSongs {
    display: grid;
    gap: 0.5rem;
}
.PageNavigation {
    padding: 1rem 0;
    justify-content: center;
    align-items: center;
}
.Prev {
    background-color: #ff7d9d;
    color: #05243a;
    padding: 0.5rem 1rem;
    margin: 0 0.2rem;
}
.Next {
    background-color: #ff7d9d;
    color: #05243a;
    padding: 0.5rem 1rem;
    margin: 0 0.2rem;
}
.Prev:hover, .Next:hover{
    cursor: pointer;
    background-color: #b3566d;
}

@media (min-width: 700px) and (min-height: 500px) {
    .AlbumSongs {
        grid-template-columns: repeat(2, minmax(220px, 1fr));
        gap: 1rem;
    }
}
@media (min-width: 700px) and (max-width: 980px) {
    .AlbumSongs {
        grid-template-columns: 1fr;
    }
}