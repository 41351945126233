.App {
    display: grid;
    height: 100vh;
    grid-template-rows: 8% 87% 5%;
    background-color: #150e56;
    background-color: #252a34;
    background-color: #7b113a;
    background-color: #d7c4fc;
    background-color: #041b2b;
    color: #f38135;
    color: #ff2e63;
    color: #ff7d9d;
}
.grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.3fr 2.5fr 0.2fr;
    gap: 0px 0px;
    grid-template-areas:
      "."
      "."
      ".";
  }
  
@media (min-height: 500px) and (min-width: 830px) {
    .App {
        grid-template-rows: 8% 92%;
        width: 100%;
        height: 100vh;
    }
}

@media (max-width: 800px) and (max-height: 400px) {
    .App {
        grid-template-rows: 15% 78% 7%;
    }
}