.NewReleases {
    display: grid;
    gap: 1rem;
}
.Album {
    position: relative;
    display: grid;
    grid-template-rows: 150px auto;
    /* justify-content: center; */
    gap: 0.5rem;
    background-color: #05243a;
    background-color: #041522;
    color: #ff7d9d;
    padding: 1rem;
}
.Info {
    /* background-color: tomato; */
    display: grid;
    align-items: center;
    /* justify-content: center; */
    /* text-align: center; */
    /* padding: 0 1rem; */
    /* gap: 0.1rem; */
}
.Title {
    color: #bebebe;
    font-size: 1.1rem;
    font-weight: bolder;
    margin-top: 0.5rem;
    letter-spacing: 1px;
}
.Artist {
    color: gray;
    font-size: 1rem;
    font-weight: 400;
    margin: 0.5rem 0;
    letter-spacing: 1px;
}
/* .Date {
    color: rgb(255, 125, 157, 0.7);
    font-size: 0.8rem;
    letter-spacing: 1px;
} */
.Cover {
    /* width: 150px; */
    /* height: 150px; */
    background-color: teal;
    box-shadow: 0 0 8px 2px black;
}
.Cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}
.Options {
    position: absolute;
    display: none;
    bottom: 50px;
    right: 10px;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
}
.PageNavigation {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Prev {
    background-color: #ff7d9d;
    color: #05243a;
    padding: 0.5rem 1rem;
    margin: 0 0.2rem;
}
.Next {
    background-color: #ff7d9d;
    color: #05243a;
    padding: 0.5rem 1rem;
    margin: 0 0.2rem;
}

@media (min-width: 700px) and (min-height: 500px) {
    .NewReleases {
        grid-template-columns: repeat(5, 1fr);
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }
    .Album {
        height: 280px;
        max-height: 320px;
        transition: box-shadow 200ms ease,
        transform 200ms ease;
    }
    .Album:hover {
        box-shadow: 0 0 8px 2px black;
        transform: scale(1.02);
    }
}