.Redirecting {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: grid;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #041b2b;
    z-index: 9999;
}
.Content {
    text-align: center;
}
.Indicater {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #041b2b;
    background-color: #ff7d9d;
    padding: 0.5rem;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    margin: 1rem auto;
}