.Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    /* background-color: rgb(6, 63, 63); */
}
.Heading {
    font-size: 2rem;
    font-weight: bolder;
    /* text-align: center; */
    margin: 1rem 0 1rem;
}
.Form {
    position: relative;
}
.Searchbar {
    display: block;
    width: 100%;
    background-color: #041520;
    color: #041b2b;
    color: white;
    padding: 0.6rem 0.5rem;
    font-size: 1rem;
    border: 1px solid transparent;
    border: 1px solid gray;
    outline: none;
    border-radius: 2px;
}
.Searchbar:focus {
    border: 1px solid #ff7d9d;
}
.Searchbar:focus + .Button {
    background-color: #ff7d9d;
}
.Button {
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: gray;
    color: #041b2b;
    padding: 0.4rem 1rem;
    font-size: 1rem;
    border: none;
    outline: none;
    border-radius: 2px;
    cursor: pointer;
}
.Button:active {
    transform: scale(0.95);
}
@media (min-width: 700px) and (min-height: 500px) {
    .Header {
        /* height: 200px; */
        border-right: 3px solid #ff7d9d;
    }
}