.SelectCategory {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #ff7d9d;
    color: #05243a;
    padding: 0.4rem;
    font-size: 1.3rem;
    cursor: pointer;
    z-index: 9999;
}
.SelectCategory:active {
    background-color: #d86582;
}
.Burger {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    display: grid;
    gap: 0.2rem;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    
}
.Burger > div {
    background-color: #05243a;
    width: 30px;
    height: 4px;
}
/* Desktop */
@media (min-width: 700px) and (min-height: 500px) {
    .SelectCategory {
        display: none;
    }
}