.Artists {
    display: grid;
    gap: 1rem;
}
.Artist {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: #05243a;
    background-color: teal;
    background-color: #041522;
    color: #ff7d9d;
    padding: 1rem;
}
.Avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: 0 0 8px 2px black;
}
.Avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 50%;
}
.Name {
    color: #bebebe;
    margin-top: 0.5rem; 
    text-align: center;
    letter-spacing: 2px;
}
.PageNavigation {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Prev {
    background-color: #ff7d9d;
    color: #05243a;
    padding: 0.5rem 1rem;
    margin: 0 0.2rem;
}
.Next {
    background-color: #ff7d9d;
    color: #05243a;
    padding: 0.5rem 1rem;
    margin: 0 0.2rem;
}

@media (min-width: 700px) and (min-height: 500px) {
    .Artists {
        grid-template-columns: repeat(5, 1fr);
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        
    }
}