.Playlists {
    display: grid;
    gap: 1rem;
}
.Playlists a {
    text-decoration: none;
}
.Playlist {
    position: relative;
    display: grid;
    grid-template-rows: 150px auto;
    /* justify-content: center; */
    gap: 0.5rem;
    background-color: #05243a;
    background-color: #041522;
    color: #ff7d9d;
    padding: 1rem;
}
.Cover {
    /* width: 150px; */
    /* height: 150px; */
    /* background-color: teal; */
    color: #05243a;
    background-color: #ff7d9d;

    box-shadow: 0 0 8px 2px black;
}
.Cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}
.Placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
}
.Info {
    display: grid;
    align-items: center;
}
.Name {
    color: #bebebe;
    font-size: 1.1rem;
    font-weight: bolder;
    margin-top: 0.5rem;
    letter-spacing: 1px;
}
.Owner {
    color: gray;
    font-size: 1rem;
    font-weight: 400;
    margin: 0.5rem 0;
    letter-spacing: 1px;
}
.PageNavigation {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Prev {
    background-color: #ff7d9d;
    color: #05243a;
    padding: 0.5rem 1rem;
    margin: 0 0.2rem;
}
.Next {
    background-color: #ff7d9d;
    color: #05243a;
    padding: 0.5rem 1rem;
    margin: 0 0.2rem;
}
.Prev:hover, .Next:hover{
    cursor: pointer;
    background-color: #b3566d;
}

@media (min-width: 700px) and (min-height: 500px) {
    .Playlists {
        grid-template-columns: repeat(5, 1fr);
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }
    .Playlist {
        transition: box-shadow 200ms ease,
        transform 200ms ease;
    }
    .Playlist:hover {
        box-shadow: 0 0 8px 2px black;
        transform: scale(1.02);
    }
}
