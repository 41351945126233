.PageNotFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.PageNotFound h2 {
    font-size: 9rem;
    letter-spacing: 20px;
    text-shadow: 4px 4px #ff386a;
}
.PageNotFound p {
    font-size: 1.5rem;
    letter-spacing: 2px;
    text-shadow: 1px 1px #ff386a;
    /* text-transform: uppercase; */
}
.Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.List {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 1rem 1rem;
    /* text-align: center; */
}
.List li {
    width: 300px;
    padding: 0.6rem 0.4rem;
}
.Link {
    display: inline-block;
    background-color: #ff7d9d;
    color: #041b2b;
    padding: 0.5rem 1.5rem;
    margin-bottom: 1rem;
    outline: none;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease;
}
.Link:hover {
    /* background-color: #c45470; */
    box-shadow: 0 0 8px black;
    transform: translateY(-2px);
    /* transform: scale(1.01); */
}

@media (min-width: 700px) and (min-height: 500px) {
    .Content {
        text-align: left;
    }
    .List {
        text-align: left;
        align-items: flex-start;
    }
    .List li {
        width: auto;
    }
}

/* Mobile Landscape */
@media (max-width: 860px) and (max-height: 400px) {
    .PageNotFound {
        margin: 2rem 0;
        padding: 1rem 0;
    }
    .List li {
        width: 380px;
    }
}