.Dashboard {
    display: grid;
    grid-template-rows: 80px auto;
    /* background-color: #053758;0 */
    height: 100%;
}

.CategoryWrapper {
    display: none;
}
/* Desktop */
@media (min-width: 700px) and (min-height: 500px) {
    .Dashboard {
        grid-template-columns: 300px auto;
        grid-template-rows: auto;
        background-color: #031827;
    }
    .Menu {
        /* position: absolute; */
        left: 0;
        top: 0;
        /* height: 100%; */
        /* width: 300px; */
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        text-align: left;
        /* border-right: 1px solid #ff7d9d; */
    }
    .CategoryWrapper {
        display: grid;
        /* background-color: teal; */
        height: 100%;
        text-align: left;
        align-items: flex-start;
    }
    
}

/* Mobile Landscape */
/* @media (max-width: 800px) and (max-height: 400px) {
    .Footer {
        background-color: none;
    }
} */