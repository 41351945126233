.Panel {
    background-color: white;
    padding: 0.2rem;
    border-radius: 5px;
}
.Content {
    background-color: #d3d3d3;
    padding: 0rem 1rem 1rem;
    width: 100%;
    border-radius: 5px;
}
.Head {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0 1rem;
    margin-top: 50px;
}
.Username {
    position: relative;
    width: 200px;
    height: 50px;
    background-color: white;
    border-radius: 5px;
    overflow-x: hidden;
}
.Username::before,
.Image::before,
.Item::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100px;
    width: 20px;
    height: 100%;
    background-color: #d3d3d3;
    animation: loading 1000ms forwards infinite ease-in-out;
    box-shadow: 0 0 20px 30px #d3d3d3;
}
.Image {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 100px;
    background-color: white;
    border: 4px solid #d3d3d3;
    border-radius: 50%;
    overflow: hidden;
}
.Main > * {
    margin: 0.5rem 0;
}
.Item {
    position: relative;
    background-color: white;
    border-radius: 5px;
    padding: 1.5rem 0.5rem;
    overflow: hidden;
}
@keyframes loading{
    to{
        left: 400px;
    }
}

@media (min-width: 700px) and (min-height: 500px) {
    .Panel {
        width: 780px;
        max-width: 780px;
        margin: 0 auto;
    }
}