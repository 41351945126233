.AlbumSkeleton {
    position: relative;
    display: grid;
    /* grid-template-rows: 150px auto; */
    gap: 0.5rem;
    background-color: #d3d3d3;
    padding: 1rem;
    overflow: hidden;
}
.AlbumSkeleton::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100px;
    width: 20px;
    height: 100%;
    background-color: #d3d3d3;
    animation: loading 1000ms forwards infinite ease-in-out;
    box-shadow: 0 0 20px 30px #d3d3d3;
}
.Cover {
    height: 120px;
    background-color: white;
}
.Title {
    width: 220px;
    height: 30px;
    background-color: white;
}
.Artist {
    width: 80px;
    height: 20px;
    background-color: white;
}
@keyframes loading{
    to{
        left: 400px;
    }
}