.Song {
    display: grid;
    grid-template-columns: 50px auto 20px;
    gap: 0.5rem;
    background-color: #05243a;
    background-color: #041522;
    color: #ff7d9d;
    box-shadow: 0 0 2px black;
    padding: 0.5rem;
}
.Info {
    display: grid;
    align-items: center;
}
.Title {
    color: #bebebe;
    font-size: 1.2rem;
    font-weight: bolder;
}
.Artist {
    color: gray;
    font-size: 0.75rem;
}
.Cover {
    width: 50px;
    height: 50px;
    box-shadow: 0 0 4px 2px black;
}
.Cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}
.Options {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}