.SongSkeleton {
    position: relative;
    display: grid;
    grid-template-columns: 50px auto 20px;
    gap: 1rem;
    background-color: #d3d3d3;
    padding: 0.5rem;
    overflow: hidden;
}
.SongSkeleton::before{
    content: '';
    position: absolute;
    top: 0;
    left: -100px;
    width: 20px;
    height: 100%;
    background-color: #d3d3d3;
    animation: loading 1000ms forwards infinite ease-in-out;
    box-shadow: 0 0 20px 30px #d3d3d3;
}
.Info {
    display: grid;
    align-items: center;
}
.Title {
    background-color: white;
    width: 200px;
    height: 20px;
}
.Artist {
    background-color: white;
    height: 10px;
    width: 100px;
}
.Cover {
    width: 50px;
    height: 50px;
    background-color: white;
}
@keyframes loading{
    to{
        left: 400px;
    }
}
/* Desktop */
@media (min-width: 700px) and (min-height: 500px) {
    .SongSkeleton {
        /* width: 48px; */
    }
}
/* Mobile Landscape */
@media (max-width: 800px) and (max-height: 400px) {
    .SongSkeleton {
        /* width: 46px; */
    }
}