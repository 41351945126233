.ProfilePicSkeleton{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 15px;
    border-radius: 50%;
    overflow: hidden;
}
.ProfilePicSkeleton::before{
    content: '';
    position: absolute;
    top: 0;
    left: -100px;
    width: 20px;
    height: 100%;
    background-color: rgba(211, 211, 211, 0.7);
    box-shadow: 
    0px 0px 40px 20px rgba(211, 211, 211, 1), 
    -0px -0px 40px 20px rgba(211, 211, 211, 0.8);
    animation: loading 1000ms forwards infinite ease-in-out;
}
@keyframes loading{
    to{
        left: 400px;
    }
}
/* Desktop */
@media (min-width: 700px) and (min-height: 500px) {
    .ProfilePicSkeleton {
        width: 50px;
        height: 50px;
    }
}
/* Mobile Landscape */
@media (max-width: 820px) and (max-height: 400px) {
    .ProfilePicSkeleton {
        width: 25px;
        height: 25px;
    }
}